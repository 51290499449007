// @generated by protobuf-ts 2.9.4 with parameter long_type_string,optimize_code_size
// @generated from protobuf file "soulfire/common.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
import { MessageType } from "@protobuf-ts/runtime";
import { Value } from "../google/protobuf/struct";
import { Struct } from "../google/protobuf/struct";
/**
 * @generated from protobuf message soulfire.v1.ProxyProto
 */
export interface ProxyProto {
    /**
     * @generated from protobuf field: soulfire.v1.ProxyProto.Type type = 1;
     */
    type: ProxyProto_Type;
    /**
     * @generated from protobuf field: string address = 2;
     */
    address: string;
    /**
     * @generated from protobuf field: optional string username = 3;
     */
    username?: string;
    /**
     * @generated from protobuf field: optional string password = 4;
     */
    password?: string;
}
/**
 * @generated from protobuf enum soulfire.v1.ProxyProto.Type
 */
export enum ProxyProto_Type {
    /**
     * @generated from protobuf enum value: HTTP = 0;
     */
    HTTP = 0,
    /**
     * @generated from protobuf enum value: SOCKS4 = 1;
     */
    SOCKS4 = 1,
    /**
     * @generated from protobuf enum value: SOCKS5 = 2;
     */
    SOCKS5 = 2
}
/**
 * @generated from protobuf message soulfire.v1.MinecraftAccountProto
 */
export interface MinecraftAccountProto {
    /**
     * @generated from protobuf field: soulfire.v1.MinecraftAccountProto.AccountTypeProto type = 1;
     */
    type: MinecraftAccountProto_AccountTypeProto;
    /**
     * @generated from protobuf field: string profile_id = 2;
     */
    profileId: string;
    /**
     * @generated from protobuf field: string last_known_name = 3;
     */
    lastKnownName: string;
    /**
     * @generated from protobuf oneof: account_data
     */
    accountData: {
        oneofKind: "onlineSimpleJavaData";
        /**
         * @generated from protobuf field: soulfire.v1.MinecraftAccountProto.OnlineSimpleJavaData online_simple_java_data = 4;
         */
        onlineSimpleJavaData: MinecraftAccountProto_OnlineSimpleJavaData;
    } | {
        oneofKind: "onlineChainJavaData";
        /**
         * @generated from protobuf field: soulfire.v1.MinecraftAccountProto.OnlineChainJavaData online_chain_java_data = 5;
         */
        onlineChainJavaData: MinecraftAccountProto_OnlineChainJavaData;
    } | {
        oneofKind: "offlineJavaData";
        /**
         * @generated from protobuf field: soulfire.v1.MinecraftAccountProto.OfflineJavaData offline_java_data = 6;
         */
        offlineJavaData: MinecraftAccountProto_OfflineJavaData;
    } | {
        oneofKind: "bedrockData";
        /**
         * @generated from protobuf field: soulfire.v1.MinecraftAccountProto.BedrockData bedrock_data = 7;
         */
        bedrockData: MinecraftAccountProto_BedrockData;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message soulfire.v1.MinecraftAccountProto.OnlineSimpleJavaData
 */
export interface MinecraftAccountProto_OnlineSimpleJavaData {
    /**
     * @generated from protobuf field: string auth_token = 1;
     */
    authToken: string;
    /**
     * @generated from protobuf field: int64 token_expire_at = 2;
     */
    tokenExpireAt: string;
}
/**
 * @generated from protobuf message soulfire.v1.MinecraftAccountProto.OnlineChainJavaData
 */
export interface MinecraftAccountProto_OnlineChainJavaData {
    /**
     * @generated from protobuf field: string auth_token = 1;
     */
    authToken: string;
    /**
     * @generated from protobuf field: int64 token_expire_at = 2;
     */
    tokenExpireAt: string;
    /**
     * @generated from protobuf field: google.protobuf.Struct auth_chain = 3;
     */
    authChain?: Struct;
}
/**
 * @generated from protobuf message soulfire.v1.MinecraftAccountProto.OfflineJavaData
 */
export interface MinecraftAccountProto_OfflineJavaData {
}
/**
 * @generated from protobuf message soulfire.v1.MinecraftAccountProto.BedrockData
 */
export interface MinecraftAccountProto_BedrockData {
    /**
     * @generated from protobuf field: string mojang_jwt = 1;
     */
    mojangJwt: string;
    /**
     * @generated from protobuf field: string identity_jwt = 2;
     */
    identityJwt: string;
    /**
     * @generated from protobuf field: string public_key = 3;
     */
    publicKey: string;
    /**
     * @generated from protobuf field: string private_key = 4;
     */
    privateKey: string;
    /**
     * @generated from protobuf field: string device_id = 5;
     */
    deviceId: string;
    /**
     * @generated from protobuf field: string play_fab_id = 6;
     */
    playFabId: string;
    /**
     * @generated from protobuf field: google.protobuf.Struct auth_chain = 7;
     */
    authChain?: Struct;
}
/**
 * @generated from protobuf enum soulfire.v1.MinecraftAccountProto.AccountTypeProto
 */
export enum MinecraftAccountProto_AccountTypeProto {
    /**
     * @generated from protobuf enum value: MICROSOFT_JAVA_CREDENTIALS = 0;
     */
    MICROSOFT_JAVA_CREDENTIALS = 0,
    /**
     * @generated from protobuf enum value: MICROSOFT_BEDROCK_CREDENTIALS = 1;
     */
    MICROSOFT_BEDROCK_CREDENTIALS = 1,
    /**
     * @generated from protobuf enum value: THE_ALTENING = 3;
     */
    THE_ALTENING = 3,
    /**
     * @generated from protobuf enum value: OFFLINE = 4;
     */
    OFFLINE = 4,
    /**
     * @generated from protobuf enum value: MICROSOFT_JAVA_DEVICE_CODE = 5;
     */
    MICROSOFT_JAVA_DEVICE_CODE = 5,
    /**
     * @generated from protobuf enum value: MICROSOFT_BEDROCK_DEVICE_CODE = 6;
     */
    MICROSOFT_BEDROCK_DEVICE_CODE = 6,
    /**
     * @generated from protobuf enum value: MICROSOFT_JAVA_REFRESH_TOKEN = 7;
     */
    MICROSOFT_JAVA_REFRESH_TOKEN = 7
}
/**
 * @generated from protobuf message soulfire.v1.SettingsEntry
 */
export interface SettingsEntry {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf field: google.protobuf.Value value = 2;
     */
    value?: Value;
}
/**
 * @generated from protobuf message soulfire.v1.SettingsNamespace
 */
export interface SettingsNamespace {
    /**
     * @generated from protobuf field: string namespace = 1;
     */
    namespace: string;
    /**
     * @generated from protobuf field: repeated soulfire.v1.SettingsEntry entries = 2;
     */
    entries: SettingsEntry[];
}
/**
 * @generated from protobuf enum soulfire.v1.AccountTypeCredentials
 */
export enum AccountTypeCredentials {
    /**
     * @generated from protobuf enum value: MICROSOFT_JAVA_CREDENTIALS = 0;
     */
    MICROSOFT_JAVA_CREDENTIALS = 0,
    /**
     * @generated from protobuf enum value: MICROSOFT_BEDROCK_CREDENTIALS = 1;
     */
    MICROSOFT_BEDROCK_CREDENTIALS = 1,
    /**
     * @generated from protobuf enum value: THE_ALTENING = 3;
     */
    THE_ALTENING = 3,
    /**
     * @generated from protobuf enum value: OFFLINE = 4;
     */
    OFFLINE = 4,
    /**
     * @generated from protobuf enum value: MICROSOFT_JAVA_REFRESH_TOKEN = 5;
     */
    MICROSOFT_JAVA_REFRESH_TOKEN = 5
}
/**
 * @generated from protobuf enum soulfire.v1.AccountTypeDeviceCode
 */
export enum AccountTypeDeviceCode {
    /**
     * @generated from protobuf enum value: MICROSOFT_JAVA_DEVICE_CODE = 0;
     */
    MICROSOFT_JAVA_DEVICE_CODE = 0,
    /**
     * @generated from protobuf enum value: MICROSOFT_BEDROCK_DEVICE_CODE = 1;
     */
    MICROSOFT_BEDROCK_DEVICE_CODE = 1
}
/**
 * @generated from protobuf enum soulfire.v1.GlobalPermission
 */
export enum GlobalPermission {
    /**
     * @generated from protobuf enum value: CREATE_INSTANCE = 0;
     */
    CREATE_INSTANCE = 0,
    /**
     * @generated from protobuf enum value: GLOBAL_SUBSCRIBE_LOGS = 1;
     */
    GLOBAL_SUBSCRIBE_LOGS = 1,
    /**
     * @generated from protobuf enum value: READ_CLIENT_DATA = 2;
     */
    READ_CLIENT_DATA = 2,
    /**
     * @generated from protobuf enum value: READ_SERVER_CONFIG = 3;
     */
    READ_SERVER_CONFIG = 3,
    /**
     * @generated from protobuf enum value: UPDATE_SERVER_CONFIG = 4;
     */
    UPDATE_SERVER_CONFIG = 4,
    /**
     * @generated from protobuf enum value: CREATE_USER = 5;
     */
    CREATE_USER = 5,
    /**
     * @generated from protobuf enum value: READ_USER = 6;
     */
    READ_USER = 6,
    /**
     * @generated from protobuf enum value: UPDATE_USER = 7;
     */
    UPDATE_USER = 7,
    /**
     * @generated from protobuf enum value: DELETE_USER = 8;
     */
    DELETE_USER = 8,
    /**
     * @generated from protobuf enum value: GLOBAL_COMMAND_EXECUTION = 9;
     */
    GLOBAL_COMMAND_EXECUTION = 9,
    /**
     * @generated from protobuf enum value: INVALIDATE_SESSIONS = 11;
     */
    INVALIDATE_SESSIONS = 11
}
/**
 * @generated from protobuf enum soulfire.v1.InstancePermission
 */
export enum InstancePermission {
    /**
     * @generated from protobuf enum value: INSTANCE_COMMAND_EXECUTION = 0;
     */
    INSTANCE_COMMAND_EXECUTION = 0,
    /**
     * @generated from protobuf enum value: READ_INSTANCE = 2;
     */
    READ_INSTANCE = 2,
    /**
     * @generated from protobuf enum value: UPDATE_INSTANCE_META = 3;
     */
    UPDATE_INSTANCE_META = 3,
    /**
     * @generated from protobuf enum value: UPDATE_INSTANCE_CONFIG = 14;
     */
    UPDATE_INSTANCE_CONFIG = 14,
    /**
     * @generated from protobuf enum value: DELETE_INSTANCE = 4;
     */
    DELETE_INSTANCE = 4,
    /**
     * @generated from protobuf enum value: CHANGE_INSTANCE_STATE = 5;
     */
    CHANGE_INSTANCE_STATE = 5,
    /**
     * @generated from protobuf enum value: AUTHENTICATE_MC_ACCOUNT = 6;
     */
    AUTHENTICATE_MC_ACCOUNT = 6,
    /**
     * @generated from protobuf enum value: CHECK_PROXY = 7;
     */
    CHECK_PROXY = 7,
    /**
     * @generated from protobuf enum value: DOWNLOAD_URL = 8;
     */
    DOWNLOAD_URL = 8,
    /**
     * @generated from protobuf enum value: UPLOAD_OBJECT_STORAGE = 9;
     */
    UPLOAD_OBJECT_STORAGE = 9,
    /**
     * @generated from protobuf enum value: DOWNLOAD_OBJECT_STORAGE = 10;
     */
    DOWNLOAD_OBJECT_STORAGE = 10,
    /**
     * @generated from protobuf enum value: DELETE_OBJECT_STORAGE = 11;
     */
    DELETE_OBJECT_STORAGE = 11,
    /**
     * @generated from protobuf enum value: LIST_OBJECT_STORAGE = 12;
     */
    LIST_OBJECT_STORAGE = 12,
    /**
     * @generated from protobuf enum value: INSTANCE_SUBSCRIBE_LOGS = 13;
     */
    INSTANCE_SUBSCRIBE_LOGS = 13,
    /**
     * @generated from protobuf enum value: READ_INSTANCE_AUDIT_LOGS = 15;
     */
    READ_INSTANCE_AUDIT_LOGS = 15
}
/**
 * @generated from protobuf enum soulfire.v1.UserRole
 */
export enum UserRole {
    /**
     * @generated from protobuf enum value: ADMIN = 0;
     */
    ADMIN = 0,
    /**
     * @generated from protobuf enum value: USER = 1;
     */
    USER = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class ProxyProto$Type extends MessageType<ProxyProto> {
    constructor() {
        super("soulfire.v1.ProxyProto", [
            { no: 1, name: "type", kind: "enum", T: () => ["soulfire.v1.ProxyProto.Type", ProxyProto_Type] },
            { no: 2, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "username", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "password", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ProxyProto
 */
export const ProxyProto = new ProxyProto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinecraftAccountProto$Type extends MessageType<MinecraftAccountProto> {
    constructor() {
        super("soulfire.v1.MinecraftAccountProto", [
            { no: 1, name: "type", kind: "enum", T: () => ["soulfire.v1.MinecraftAccountProto.AccountTypeProto", MinecraftAccountProto_AccountTypeProto] },
            { no: 2, name: "profile_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_known_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "online_simple_java_data", kind: "message", oneof: "accountData", T: () => MinecraftAccountProto_OnlineSimpleJavaData },
            { no: 5, name: "online_chain_java_data", kind: "message", oneof: "accountData", T: () => MinecraftAccountProto_OnlineChainJavaData },
            { no: 6, name: "offline_java_data", kind: "message", oneof: "accountData", T: () => MinecraftAccountProto_OfflineJavaData },
            { no: 7, name: "bedrock_data", kind: "message", oneof: "accountData", T: () => MinecraftAccountProto_BedrockData }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinecraftAccountProto
 */
export const MinecraftAccountProto = new MinecraftAccountProto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinecraftAccountProto_OnlineSimpleJavaData$Type extends MessageType<MinecraftAccountProto_OnlineSimpleJavaData> {
    constructor() {
        super("soulfire.v1.MinecraftAccountProto.OnlineSimpleJavaData", [
            { no: 1, name: "auth_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token_expire_at", kind: "scalar", T: 3 /*ScalarType.INT64*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinecraftAccountProto.OnlineSimpleJavaData
 */
export const MinecraftAccountProto_OnlineSimpleJavaData = new MinecraftAccountProto_OnlineSimpleJavaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinecraftAccountProto_OnlineChainJavaData$Type extends MessageType<MinecraftAccountProto_OnlineChainJavaData> {
    constructor() {
        super("soulfire.v1.MinecraftAccountProto.OnlineChainJavaData", [
            { no: 1, name: "auth_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token_expire_at", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 3, name: "auth_chain", kind: "message", T: () => Struct }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinecraftAccountProto.OnlineChainJavaData
 */
export const MinecraftAccountProto_OnlineChainJavaData = new MinecraftAccountProto_OnlineChainJavaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinecraftAccountProto_OfflineJavaData$Type extends MessageType<MinecraftAccountProto_OfflineJavaData> {
    constructor() {
        super("soulfire.v1.MinecraftAccountProto.OfflineJavaData", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinecraftAccountProto.OfflineJavaData
 */
export const MinecraftAccountProto_OfflineJavaData = new MinecraftAccountProto_OfflineJavaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinecraftAccountProto_BedrockData$Type extends MessageType<MinecraftAccountProto_BedrockData> {
    constructor() {
        super("soulfire.v1.MinecraftAccountProto.BedrockData", [
            { no: 1, name: "mojang_jwt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "identity_jwt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "public_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "private_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "play_fab_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "auth_chain", kind: "message", T: () => Struct }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinecraftAccountProto.BedrockData
 */
export const MinecraftAccountProto_BedrockData = new MinecraftAccountProto_BedrockData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SettingsEntry$Type extends MessageType<SettingsEntry> {
    constructor() {
        super("soulfire.v1.SettingsEntry", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "message", T: () => Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.SettingsEntry
 */
export const SettingsEntry = new SettingsEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SettingsNamespace$Type extends MessageType<SettingsNamespace> {
    constructor() {
        super("soulfire.v1.SettingsNamespace", [
            { no: 1, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "entries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SettingsEntry }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.SettingsNamespace
 */
export const SettingsNamespace = new SettingsNamespace$Type();
