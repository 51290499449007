/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as DashboardImport } from './routes/dashboard'
import { Route as IndexImport } from './routes/index'
import { Route as DashboardUserImport } from './routes/dashboard/user'
import { Route as DashboardUserInstancesImport } from './routes/dashboard/user/instances'
import { Route as DashboardUserAdminImport } from './routes/dashboard/user/admin'
import { Route as DashboardInstanceInstanceImport } from './routes/dashboard/instance/$instance'
import { Route as DashboardUserAdminOverviewImport } from './routes/dashboard/user/admin/overview'
import { Route as DashboardUserAdminConsoleImport } from './routes/dashboard/user/admin/console'
import { Route as DashboardInstanceInstanceProxiesImport } from './routes/dashboard/instance/$instance/proxies'
import { Route as DashboardInstanceInstanceDiscoverImport } from './routes/dashboard/instance/$instance/discover'
import { Route as DashboardInstanceInstanceConsoleImport } from './routes/dashboard/instance/$instance/console'
import { Route as DashboardInstanceInstanceAuditLogImport } from './routes/dashboard/instance/$instance/audit-log'
import { Route as DashboardInstanceInstanceAccountsImport } from './routes/dashboard/instance/$instance/accounts'
import { Route as DashboardUserAdminSettingsNamespaceImport } from './routes/dashboard/user/admin/settings/$namespace'
import { Route as DashboardInstanceInstanceSettingsNamespaceImport } from './routes/dashboard/instance/$instance/settings/$namespace'

// Create/Update Routes

const DashboardRoute = DashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const DashboardUserRoute = DashboardUserImport.update({
  id: '/user',
  path: '/user',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardUserInstancesRoute = DashboardUserInstancesImport.update({
  id: '/instances',
  path: '/instances',
  getParentRoute: () => DashboardUserRoute,
} as any)

const DashboardUserAdminRoute = DashboardUserAdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => DashboardUserRoute,
} as any)

const DashboardInstanceInstanceRoute = DashboardInstanceInstanceImport.update({
  id: '/instance/$instance',
  path: '/instance/$instance',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardUserAdminOverviewRoute = DashboardUserAdminOverviewImport.update(
  {
    id: '/overview',
    path: '/overview',
    getParentRoute: () => DashboardUserAdminRoute,
  } as any,
)

const DashboardUserAdminConsoleRoute = DashboardUserAdminConsoleImport.update({
  id: '/console',
  path: '/console',
  getParentRoute: () => DashboardUserAdminRoute,
} as any)

const DashboardInstanceInstanceProxiesRoute =
  DashboardInstanceInstanceProxiesImport.update({
    id: '/proxies',
    path: '/proxies',
    getParentRoute: () => DashboardInstanceInstanceRoute,
  } as any)

const DashboardInstanceInstanceDiscoverRoute =
  DashboardInstanceInstanceDiscoverImport.update({
    id: '/discover',
    path: '/discover',
    getParentRoute: () => DashboardInstanceInstanceRoute,
  } as any)

const DashboardInstanceInstanceConsoleRoute =
  DashboardInstanceInstanceConsoleImport.update({
    id: '/console',
    path: '/console',
    getParentRoute: () => DashboardInstanceInstanceRoute,
  } as any)

const DashboardInstanceInstanceAuditLogRoute =
  DashboardInstanceInstanceAuditLogImport.update({
    id: '/audit-log',
    path: '/audit-log',
    getParentRoute: () => DashboardInstanceInstanceRoute,
  } as any)

const DashboardInstanceInstanceAccountsRoute =
  DashboardInstanceInstanceAccountsImport.update({
    id: '/accounts',
    path: '/accounts',
    getParentRoute: () => DashboardInstanceInstanceRoute,
  } as any)

const DashboardUserAdminSettingsNamespaceRoute =
  DashboardUserAdminSettingsNamespaceImport.update({
    id: '/settings/$namespace',
    path: '/settings/$namespace',
    getParentRoute: () => DashboardUserAdminRoute,
  } as any)

const DashboardInstanceInstanceSettingsNamespaceRoute =
  DashboardInstanceInstanceSettingsNamespaceImport.update({
    id: '/settings/$namespace',
    path: '/settings/$namespace',
    getParentRoute: () => DashboardInstanceInstanceRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/dashboard': {
      id: '/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/user': {
      id: '/dashboard/user'
      path: '/user'
      fullPath: '/dashboard/user'
      preLoaderRoute: typeof DashboardUserImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/instance/$instance': {
      id: '/dashboard/instance/$instance'
      path: '/instance/$instance'
      fullPath: '/dashboard/instance/$instance'
      preLoaderRoute: typeof DashboardInstanceInstanceImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/user/admin': {
      id: '/dashboard/user/admin'
      path: '/admin'
      fullPath: '/dashboard/user/admin'
      preLoaderRoute: typeof DashboardUserAdminImport
      parentRoute: typeof DashboardUserImport
    }
    '/dashboard/user/instances': {
      id: '/dashboard/user/instances'
      path: '/instances'
      fullPath: '/dashboard/user/instances'
      preLoaderRoute: typeof DashboardUserInstancesImport
      parentRoute: typeof DashboardUserImport
    }
    '/dashboard/instance/$instance/accounts': {
      id: '/dashboard/instance/$instance/accounts'
      path: '/accounts'
      fullPath: '/dashboard/instance/$instance/accounts'
      preLoaderRoute: typeof DashboardInstanceInstanceAccountsImport
      parentRoute: typeof DashboardInstanceInstanceImport
    }
    '/dashboard/instance/$instance/audit-log': {
      id: '/dashboard/instance/$instance/audit-log'
      path: '/audit-log'
      fullPath: '/dashboard/instance/$instance/audit-log'
      preLoaderRoute: typeof DashboardInstanceInstanceAuditLogImport
      parentRoute: typeof DashboardInstanceInstanceImport
    }
    '/dashboard/instance/$instance/console': {
      id: '/dashboard/instance/$instance/console'
      path: '/console'
      fullPath: '/dashboard/instance/$instance/console'
      preLoaderRoute: typeof DashboardInstanceInstanceConsoleImport
      parentRoute: typeof DashboardInstanceInstanceImport
    }
    '/dashboard/instance/$instance/discover': {
      id: '/dashboard/instance/$instance/discover'
      path: '/discover'
      fullPath: '/dashboard/instance/$instance/discover'
      preLoaderRoute: typeof DashboardInstanceInstanceDiscoverImport
      parentRoute: typeof DashboardInstanceInstanceImport
    }
    '/dashboard/instance/$instance/proxies': {
      id: '/dashboard/instance/$instance/proxies'
      path: '/proxies'
      fullPath: '/dashboard/instance/$instance/proxies'
      preLoaderRoute: typeof DashboardInstanceInstanceProxiesImport
      parentRoute: typeof DashboardInstanceInstanceImport
    }
    '/dashboard/user/admin/console': {
      id: '/dashboard/user/admin/console'
      path: '/console'
      fullPath: '/dashboard/user/admin/console'
      preLoaderRoute: typeof DashboardUserAdminConsoleImport
      parentRoute: typeof DashboardUserAdminImport
    }
    '/dashboard/user/admin/overview': {
      id: '/dashboard/user/admin/overview'
      path: '/overview'
      fullPath: '/dashboard/user/admin/overview'
      preLoaderRoute: typeof DashboardUserAdminOverviewImport
      parentRoute: typeof DashboardUserAdminImport
    }
    '/dashboard/instance/$instance/settings/$namespace': {
      id: '/dashboard/instance/$instance/settings/$namespace'
      path: '/settings/$namespace'
      fullPath: '/dashboard/instance/$instance/settings/$namespace'
      preLoaderRoute: typeof DashboardInstanceInstanceSettingsNamespaceImport
      parentRoute: typeof DashboardInstanceInstanceImport
    }
    '/dashboard/user/admin/settings/$namespace': {
      id: '/dashboard/user/admin/settings/$namespace'
      path: '/settings/$namespace'
      fullPath: '/dashboard/user/admin/settings/$namespace'
      preLoaderRoute: typeof DashboardUserAdminSettingsNamespaceImport
      parentRoute: typeof DashboardUserAdminImport
    }
  }
}

// Create and export the route tree

interface DashboardUserAdminRouteChildren {
  DashboardUserAdminConsoleRoute: typeof DashboardUserAdminConsoleRoute
  DashboardUserAdminOverviewRoute: typeof DashboardUserAdminOverviewRoute
  DashboardUserAdminSettingsNamespaceRoute: typeof DashboardUserAdminSettingsNamespaceRoute
}

const DashboardUserAdminRouteChildren: DashboardUserAdminRouteChildren = {
  DashboardUserAdminConsoleRoute: DashboardUserAdminConsoleRoute,
  DashboardUserAdminOverviewRoute: DashboardUserAdminOverviewRoute,
  DashboardUserAdminSettingsNamespaceRoute:
    DashboardUserAdminSettingsNamespaceRoute,
}

const DashboardUserAdminRouteWithChildren =
  DashboardUserAdminRoute._addFileChildren(DashboardUserAdminRouteChildren)

interface DashboardUserRouteChildren {
  DashboardUserAdminRoute: typeof DashboardUserAdminRouteWithChildren
  DashboardUserInstancesRoute: typeof DashboardUserInstancesRoute
}

const DashboardUserRouteChildren: DashboardUserRouteChildren = {
  DashboardUserAdminRoute: DashboardUserAdminRouteWithChildren,
  DashboardUserInstancesRoute: DashboardUserInstancesRoute,
}

const DashboardUserRouteWithChildren = DashboardUserRoute._addFileChildren(
  DashboardUserRouteChildren,
)

interface DashboardInstanceInstanceRouteChildren {
  DashboardInstanceInstanceAccountsRoute: typeof DashboardInstanceInstanceAccountsRoute
  DashboardInstanceInstanceAuditLogRoute: typeof DashboardInstanceInstanceAuditLogRoute
  DashboardInstanceInstanceConsoleRoute: typeof DashboardInstanceInstanceConsoleRoute
  DashboardInstanceInstanceDiscoverRoute: typeof DashboardInstanceInstanceDiscoverRoute
  DashboardInstanceInstanceProxiesRoute: typeof DashboardInstanceInstanceProxiesRoute
  DashboardInstanceInstanceSettingsNamespaceRoute: typeof DashboardInstanceInstanceSettingsNamespaceRoute
}

const DashboardInstanceInstanceRouteChildren: DashboardInstanceInstanceRouteChildren =
  {
    DashboardInstanceInstanceAccountsRoute:
      DashboardInstanceInstanceAccountsRoute,
    DashboardInstanceInstanceAuditLogRoute:
      DashboardInstanceInstanceAuditLogRoute,
    DashboardInstanceInstanceConsoleRoute:
      DashboardInstanceInstanceConsoleRoute,
    DashboardInstanceInstanceDiscoverRoute:
      DashboardInstanceInstanceDiscoverRoute,
    DashboardInstanceInstanceProxiesRoute:
      DashboardInstanceInstanceProxiesRoute,
    DashboardInstanceInstanceSettingsNamespaceRoute:
      DashboardInstanceInstanceSettingsNamespaceRoute,
  }

const DashboardInstanceInstanceRouteWithChildren =
  DashboardInstanceInstanceRoute._addFileChildren(
    DashboardInstanceInstanceRouteChildren,
  )

interface DashboardRouteChildren {
  DashboardUserRoute: typeof DashboardUserRouteWithChildren
  DashboardInstanceInstanceRoute: typeof DashboardInstanceInstanceRouteWithChildren
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardUserRoute: DashboardUserRouteWithChildren,
  DashboardInstanceInstanceRoute: DashboardInstanceInstanceRouteWithChildren,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/dashboard/user': typeof DashboardUserRouteWithChildren
  '/dashboard/instance/$instance': typeof DashboardInstanceInstanceRouteWithChildren
  '/dashboard/user/admin': typeof DashboardUserAdminRouteWithChildren
  '/dashboard/user/instances': typeof DashboardUserInstancesRoute
  '/dashboard/instance/$instance/accounts': typeof DashboardInstanceInstanceAccountsRoute
  '/dashboard/instance/$instance/audit-log': typeof DashboardInstanceInstanceAuditLogRoute
  '/dashboard/instance/$instance/console': typeof DashboardInstanceInstanceConsoleRoute
  '/dashboard/instance/$instance/discover': typeof DashboardInstanceInstanceDiscoverRoute
  '/dashboard/instance/$instance/proxies': typeof DashboardInstanceInstanceProxiesRoute
  '/dashboard/user/admin/console': typeof DashboardUserAdminConsoleRoute
  '/dashboard/user/admin/overview': typeof DashboardUserAdminOverviewRoute
  '/dashboard/instance/$instance/settings/$namespace': typeof DashboardInstanceInstanceSettingsNamespaceRoute
  '/dashboard/user/admin/settings/$namespace': typeof DashboardUserAdminSettingsNamespaceRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/dashboard/user': typeof DashboardUserRouteWithChildren
  '/dashboard/instance/$instance': typeof DashboardInstanceInstanceRouteWithChildren
  '/dashboard/user/admin': typeof DashboardUserAdminRouteWithChildren
  '/dashboard/user/instances': typeof DashboardUserInstancesRoute
  '/dashboard/instance/$instance/accounts': typeof DashboardInstanceInstanceAccountsRoute
  '/dashboard/instance/$instance/audit-log': typeof DashboardInstanceInstanceAuditLogRoute
  '/dashboard/instance/$instance/console': typeof DashboardInstanceInstanceConsoleRoute
  '/dashboard/instance/$instance/discover': typeof DashboardInstanceInstanceDiscoverRoute
  '/dashboard/instance/$instance/proxies': typeof DashboardInstanceInstanceProxiesRoute
  '/dashboard/user/admin/console': typeof DashboardUserAdminConsoleRoute
  '/dashboard/user/admin/overview': typeof DashboardUserAdminOverviewRoute
  '/dashboard/instance/$instance/settings/$namespace': typeof DashboardInstanceInstanceSettingsNamespaceRoute
  '/dashboard/user/admin/settings/$namespace': typeof DashboardUserAdminSettingsNamespaceRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/dashboard/user': typeof DashboardUserRouteWithChildren
  '/dashboard/instance/$instance': typeof DashboardInstanceInstanceRouteWithChildren
  '/dashboard/user/admin': typeof DashboardUserAdminRouteWithChildren
  '/dashboard/user/instances': typeof DashboardUserInstancesRoute
  '/dashboard/instance/$instance/accounts': typeof DashboardInstanceInstanceAccountsRoute
  '/dashboard/instance/$instance/audit-log': typeof DashboardInstanceInstanceAuditLogRoute
  '/dashboard/instance/$instance/console': typeof DashboardInstanceInstanceConsoleRoute
  '/dashboard/instance/$instance/discover': typeof DashboardInstanceInstanceDiscoverRoute
  '/dashboard/instance/$instance/proxies': typeof DashboardInstanceInstanceProxiesRoute
  '/dashboard/user/admin/console': typeof DashboardUserAdminConsoleRoute
  '/dashboard/user/admin/overview': typeof DashboardUserAdminOverviewRoute
  '/dashboard/instance/$instance/settings/$namespace': typeof DashboardInstanceInstanceSettingsNamespaceRoute
  '/dashboard/user/admin/settings/$namespace': typeof DashboardUserAdminSettingsNamespaceRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/dashboard'
    | '/dashboard/user'
    | '/dashboard/instance/$instance'
    | '/dashboard/user/admin'
    | '/dashboard/user/instances'
    | '/dashboard/instance/$instance/accounts'
    | '/dashboard/instance/$instance/audit-log'
    | '/dashboard/instance/$instance/console'
    | '/dashboard/instance/$instance/discover'
    | '/dashboard/instance/$instance/proxies'
    | '/dashboard/user/admin/console'
    | '/dashboard/user/admin/overview'
    | '/dashboard/instance/$instance/settings/$namespace'
    | '/dashboard/user/admin/settings/$namespace'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/dashboard'
    | '/dashboard/user'
    | '/dashboard/instance/$instance'
    | '/dashboard/user/admin'
    | '/dashboard/user/instances'
    | '/dashboard/instance/$instance/accounts'
    | '/dashboard/instance/$instance/audit-log'
    | '/dashboard/instance/$instance/console'
    | '/dashboard/instance/$instance/discover'
    | '/dashboard/instance/$instance/proxies'
    | '/dashboard/user/admin/console'
    | '/dashboard/user/admin/overview'
    | '/dashboard/instance/$instance/settings/$namespace'
    | '/dashboard/user/admin/settings/$namespace'
  id:
    | '__root__'
    | '/'
    | '/dashboard'
    | '/dashboard/user'
    | '/dashboard/instance/$instance'
    | '/dashboard/user/admin'
    | '/dashboard/user/instances'
    | '/dashboard/instance/$instance/accounts'
    | '/dashboard/instance/$instance/audit-log'
    | '/dashboard/instance/$instance/console'
    | '/dashboard/instance/$instance/discover'
    | '/dashboard/instance/$instance/proxies'
    | '/dashboard/user/admin/console'
    | '/dashboard/user/admin/overview'
    | '/dashboard/instance/$instance/settings/$namespace'
    | '/dashboard/user/admin/settings/$namespace'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DashboardRoute: typeof DashboardRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DashboardRoute: DashboardRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/dashboard"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/dashboard": {
      "filePath": "dashboard.tsx",
      "children": [
        "/dashboard/user",
        "/dashboard/instance/$instance"
      ]
    },
    "/dashboard/user": {
      "filePath": "dashboard/user.tsx",
      "parent": "/dashboard",
      "children": [
        "/dashboard/user/admin",
        "/dashboard/user/instances"
      ]
    },
    "/dashboard/instance/$instance": {
      "filePath": "dashboard/instance/$instance.tsx",
      "parent": "/dashboard",
      "children": [
        "/dashboard/instance/$instance/accounts",
        "/dashboard/instance/$instance/audit-log",
        "/dashboard/instance/$instance/console",
        "/dashboard/instance/$instance/discover",
        "/dashboard/instance/$instance/proxies",
        "/dashboard/instance/$instance/settings/$namespace"
      ]
    },
    "/dashboard/user/admin": {
      "filePath": "dashboard/user/admin.tsx",
      "parent": "/dashboard/user",
      "children": [
        "/dashboard/user/admin/console",
        "/dashboard/user/admin/overview",
        "/dashboard/user/admin/settings/$namespace"
      ]
    },
    "/dashboard/user/instances": {
      "filePath": "dashboard/user/instances.tsx",
      "parent": "/dashboard/user"
    },
    "/dashboard/instance/$instance/accounts": {
      "filePath": "dashboard/instance/$instance/accounts.tsx",
      "parent": "/dashboard/instance/$instance"
    },
    "/dashboard/instance/$instance/audit-log": {
      "filePath": "dashboard/instance/$instance/audit-log.tsx",
      "parent": "/dashboard/instance/$instance"
    },
    "/dashboard/instance/$instance/console": {
      "filePath": "dashboard/instance/$instance/console.tsx",
      "parent": "/dashboard/instance/$instance"
    },
    "/dashboard/instance/$instance/discover": {
      "filePath": "dashboard/instance/$instance/discover.tsx",
      "parent": "/dashboard/instance/$instance"
    },
    "/dashboard/instance/$instance/proxies": {
      "filePath": "dashboard/instance/$instance/proxies.tsx",
      "parent": "/dashboard/instance/$instance"
    },
    "/dashboard/user/admin/console": {
      "filePath": "dashboard/user/admin/console.tsx",
      "parent": "/dashboard/user/admin"
    },
    "/dashboard/user/admin/overview": {
      "filePath": "dashboard/user/admin/overview.tsx",
      "parent": "/dashboard/user/admin"
    },
    "/dashboard/instance/$instance/settings/$namespace": {
      "filePath": "dashboard/instance/$instance/settings/$namespace.tsx",
      "parent": "/dashboard/instance/$instance"
    },
    "/dashboard/user/admin/settings/$namespace": {
      "filePath": "dashboard/user/admin/settings/$namespace.tsx",
      "parent": "/dashboard/user/admin"
    }
  }
}
ROUTE_MANIFEST_END */
